/*=========================================================================================
  File Name: moduleOtherPurchase.js
  Description: OtherPurchase Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleOtherPurchaseState.js'
import mutations from './moduleOtherPurchaseMutations.js'
import actions from './moduleOtherPurchaseActions.js'
import getters from './moduleOtherPurchaseGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
