/*=========================================================================================
  File Name: moduleDelivery.js
  Description: Delivery Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleDeliveryState.js'
import mutations from './moduleDeliveryMutations.js'
import actions from './moduleDeliveryActions.js'
import getters from './moduleDeliveryGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}