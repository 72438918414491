/*=========================================================================================
  File Name: moduleProductionRules.js
  Description: ProductionRules Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleProductionRulesState.js'
import mutations from './moduleProductionRulesMutations.js'
import actions from './moduleProductionRulesActions.js'
import getters from './moduleProductionRulesGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}