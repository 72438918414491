/*=========================================================================================
  File Name: moduleProductPriceActions.js
  Description: ProductPrice Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/

  export default {
    setParentGroupName({ commit }, payload){
      commit('SET_PARENT_GROUP_NAME', payload);
    },
    setPriceGroupName({ commit }, payload){
      commit('SET_PRICE_GROUP_NAME', payload);
    },
    setActiveStatus({ commit }, payload){
      commit('SET_ACTIVE_STATUS', payload);
    },
    loadAllRecords({ commit }, payload){

      var output="";
      var successTxt="";
      var errorTxt="";
      var flag=0;
      var records=[];
      var totalRecords=0;
      
      var url=payload.list_url;
      console.log('url='+url);
      
      var upload = {
        'token' : payload.token,
        'where' : payload.list_where
      };
      
      payload.loading({ type:'material', });
      
      payload.http({
        url: url,
        method: 'POST',
        data: upload,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(function (response) {
        console.log('response='+JSON.stringify(response));
        output = response.data.output;
        flag = response.data.flag;
        successTxt = response.data.success;
        errorTxt = response.data.error;
        console.log('output='+output+', flag='+flag);
        console.log('successTxt='+successTxt+', errorTxt='+errorTxt);
        payload.loading.close();
        if (flag == 1) {
          records = response.data.records;
          totalRecords= records.length;
          console.log('totalRecords='+totalRecords);
          commit('LOAD_ALL_RECORDS', records);
          /*
          payload.notify({
            title:'Success',
            text: successTxt,
            color:'success',
            iconPack: 'feather',
            icon:'icon-check',
            position: 'top-center'
          });
          */
        }else{
          payload.notify({
            title:'Failed',
            text: errorTxt,
            color:'warning',
            iconPack: 'feather',
            icon:'icon-alert-circle',
            position: 'top-center'
          });
        }
      })
      .catch(function (error) {
        console.log('error='+error);
      });
    },
    addRecordDetails({ dispatch }, payload) {
      var output="";
      var successTxt="";
      var errorTxt="";
      var flag=0;
      var getRecords={};
    
      getRecords.token=payload.token;
      getRecords.list_url=payload.list_url;
      getRecords.list_where=payload.list_where;
      getRecords.loading=payload.loading;
      getRecords.notify=payload.notify;
      getRecords.http=payload.http;

      var url=payload.add_url;
      console.log('url='+url);
      
      var upload = {
        'token' : payload.token,
        'form' : payload.form
      };
      console.log('upload='+JSON.stringify(upload));

      payload.loading({ type:'material', });
      payload.http({
        url: url,
        method: 'POST',
        data: upload,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(function (response) {
        console.log('response='+JSON.stringify(response));
        output = response.data.output;
        flag = response.data.flag;
        successTxt = response.data.success;
        errorTxt = response.data.error;
        console.log('output='+output+', flag='+flag);
        console.log('successTxt='+successTxt+', errorTxt='+errorTxt);
        payload.loading.close();
        if (flag == 1) {
          dispatch('loadAllRecords', getRecords);
          payload.notify({
            title:'Success',
            text: successTxt,
            color:'success',
            iconPack: 'feather',
            icon:'icon-check',
            position: 'top-center'
          });
        }else{
          payload.notify({
            title:'Failed',
            text: errorTxt,
            color:'warning',
            iconPack: 'feather',
            icon:'icon-alert-circle',
            position: 'top-center'
          });
        }
      })
      .catch(function (error) {
        console.log('error='+error);
      });
    },
    updateRecordDetails({ dispatch }, payload) {
      var output="";
      var successTxt="";
      var errorTxt="";
      var flag=0;
      var getRecords={};

      getRecords.token=payload.token;
      getRecords.list_url=payload.list_url;
      getRecords.list_where=payload.list_where;
      getRecords.loading=payload.loading;
      getRecords.notify=payload.notify;
      getRecords.http=payload.http;
      
      var url=payload.update_url;
      console.log('url='+url);
      
      var upload = {
        'token' : payload.token,
        'where' : payload.where,
        'form' : payload.form
      };
      console.log('upload='+JSON.stringify(upload));

      payload.loading({ type:'material', });
      payload.http({
        url: url,
        method: 'POST',
        data: upload,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(function (response) {
        console.log('response='+JSON.stringify(response));
        output = response.data.output;
        flag = response.data.flag;
        successTxt = response.data.success;
        errorTxt = response.data.error;
        console.log('output='+output+', flag='+flag);
        console.log('successTxt='+successTxt+', errorTxt='+errorTxt);
        payload.loading.close();
        if (flag == 1) {
          dispatch('loadAllRecords', getRecords);
          payload.notify({
            title:'Success',
            text: successTxt,
            color:'success',
            iconPack: 'feather',
            icon:'icon-check',
            position: 'top-center'
          });
        }else{
          payload.notify({
            title:'Failed',            
            text: errorTxt,
            color:'warning',
            iconPack: 'feather',
            icon:'icon-alert-circle',
            position: 'top-center'
          });
        }
      })
      .catch(function (error) {
        console.log('error='+error);
      });
    },
    deleteRecordDetails({ dispatch }, payload) {
      var output="";
      var successTxt="";
      var errorTxt="";
      var flag=0;
      var getRecords={};

      getRecords.token=payload.token;
      getRecords.list_url=payload.list_url;
      getRecords.list_where=payload.list_where;
      getRecords.loading=payload.loading;
      getRecords.notify=payload.notify;
      getRecords.http=payload.http;

      var url=payload.delete_url;
      console.log('url='+url);
      
      var upload = {
        'token' : payload.token,
        'where' : payload.where,
      };
      console.log('upload='+JSON.stringify(upload));

      payload.loading({ type:'material', });
      payload.http({
        url: url,
        method: 'POST',
        data: upload,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(function (response) {
        console.log('response='+JSON.stringify(response));
        output = response.data.output;
        flag = response.data.flag;
        successTxt = response.data.success;
        errorTxt = response.data.error;
        console.log('output='+output+', flag='+flag);
        console.log('successTxt='+successTxt+', errorTxt='+errorTxt);
        payload.loading.close();
        if (flag == 1) {
          dispatch('loadAllRecords', getRecords);
          payload.notify({
            title:'Success',
            text: successTxt,
            color:'success',
            iconPack: 'feather',
            icon:'icon-check',
            position: 'top-center'
          });
        }else{
          payload.notify({
            title:'Failed',
            text: errorTxt,
            color:'warning',
            iconPack: 'feather',
            icon:'icon-alert-circle',
            position: 'top-center'
          });
        }
      })
      .catch(function (error) {
        console.log('error='+error);
      });
    },        
  }