/*=========================================================================================
  File Name: moduleConversion.js
  Description: Conversion Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleConversionState.js'
import mutations from './moduleConversionMutations.js'
import actions from './moduleConversionActions.js'
import getters from './moduleConversionGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}