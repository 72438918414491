/*=========================================================================================
  File Name: moduleLocalCessRulesState.js
  Description: LocalCessRules Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


export default {
    dataArray: []
}