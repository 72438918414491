/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleTodo from './todo/moduleTodo.js'
import moduleCalendar from './calendar/moduleCalendar.js'
import moduleECommerce from './eCommerce/moduleECommerce.js'
import moduleUsers from './users/moduleUsers.js'
import modulePartyPriceGroup from './partyPriceGroup/modulePartyPriceGroup.js'
import moduleCity from './city/moduleCity.js'
import moduleGstSlab from './gstSlab/moduleGstSlab.js'
import moduleAllParty from './allParty/moduleAllParty.js'
import moduleLedger from './ledger/moduleLedger.js'
import moduleLedgerGroup from './ledgerGroup/moduleLedgerGroup.js'
import moduleProductGroup from './productGroup/moduleProductGroup.js'
import moduleProductUnit from './productUnit/moduleProductUnit.js'
import moduleProduct from './product/moduleProduct.js'
import modulePurchase from './purchase/modulePurchase.js'
import moduleSales from './sales/moduleSales.js'
import moduleTransport from './transport/moduleTransport.js'
import moduleDelivery from './delivery/moduleDelivery.js'
import modulePaymentMode from './paymentMode/modulePaymentMode.js'
import moduleProductPrice from './productPrice/moduleProductPrice.js'
import moduleAllTransaction from './allTransaction/moduleAllTransaction.js'
import moduleCessRules from './cessRules/moduleCessRules.js'
import moduleLocalCessType from './localCessType/moduleLocalCessType.js'
import moduleLocalCessRules from './localCessRules/moduleLocalCessRules.js'
import moduleProductionRules from './productionRules/moduleProductionRules.js'
import moduleProductionList from './productionList/moduleProductionList.js'
import moduleCompanySettings from './companySettings/moduleCompanySettings.js'
import moduleCompanyDetails from './companyDetails/moduleCompanyDetails.js'
import moduleCompanyRegistration from './companyRegistration/moduleCompanyRegistration.js'
import moduleAllPriceRules from './allPriceRules/moduleAllPriceRules.js'
import moduleDeliveryChallan from './deliveryChallan/moduleDeliveryChallan.js'
import moduleAllCompanyParty from './allCompanyParty/moduleAllCompanyParty.js'
import modulePurchaseOrder from './purchaseOrder/modulePurchaseOrder.js'
import moduleProductComboPack from './productComboPack/moduleProductComboPack.js'
import moduleOtherPurchase from './otherPurchase/moduleOtherPurchase.js'
import moduleSettings from './settings/moduleSettings.js'
import moduleConversion from './conversion/moduleConversion.js'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    todo: moduleTodo,
    calendar: moduleCalendar,
    eCommerce: moduleECommerce,
    users: moduleUsers,
    partyPriceGroup: modulePartyPriceGroup,
    city: moduleCity,
    gstSlab: moduleGstSlab,
    allParty: moduleAllParty,
    ledger: moduleLedger,
    ledgerGroup: moduleLedgerGroup,
    productGroup: moduleProductGroup,
    productUnit: moduleProductUnit,
    product: moduleProduct,
    purchase: modulePurchase,
    sales: moduleSales,
    transport: moduleTransport,
    delivery: moduleDelivery,
    paymentMode: modulePaymentMode,
    productPrice: moduleProductPrice,
    allTransaction: moduleAllTransaction,
    cessRules: moduleCessRules,
    localCessType: moduleLocalCessType,
    localCessRules: moduleLocalCessRules,
    productionRules: moduleProductionRules,
    productionList: moduleProductionList,
    companySettings: moduleCompanySettings,
    companyDetails: moduleCompanyDetails,
    companyRegistration: moduleCompanyRegistration,
    allPriceRules: moduleAllPriceRules,
    deliveryChallan: moduleDeliveryChallan,
    allCompanyParty: moduleAllCompanyParty,
    purchaseOrder: modulePurchaseOrder,
    productComboPack: moduleProductComboPack,
    otherPurchase: moduleOtherPurchase,
    settings: moduleSettings,
    Conversion: moduleConversion,
  },
  strict: process.env.NODE_ENV !== 'Production'
})
