<template>
  <div class="about">
    <h1>This is an common functions component.</h1>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import themeConfig from "@/themeConfig.js";

export default {
  name: "CommonFunctions",
  props: [],
  mounted() {},
  data() {
    return {
      themeConfig: themeConfig,
    };
  },
  methods: {
    getCurrentDate() {
      var currentDate = new Date().toISOString().slice(0, 10);
      console.log({ currentDate });
      return currentDate;
    },
    getCurrentTime() {
      var currentTime = new Date();
      var hours = currentTime.getHours().toString().padStart(2, "0");
      var minutes = currentTime.getMinutes().toString().padStart(2, "0");
      var formattedTime = `${hours}:${minutes}`;
      return formattedTime;
    },
    getDecimalNumber(value, decimals) {
      return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
    },
    LogoutUser() {
      console.log("LogoutUser called");
      localStorage.clear();
      this.$session.destroy();
      this.$router.push("/login");
    },
    redirectPreviousPage() {
      this.$router.go(-1);
    },
    pageRedirect(params, url) {
      console.log("pageRedirect function is called");
      console.log("url=" + url + ", params=" + JSON.stringify(params));
      var n1 = Object.keys(params).length;
      console.log("n1=" + n1);
      // this.pageLoadingOn();
      if (n1 > 0) {
        this.$router.push({
          path: url,
          query: params,
        });
      } else {
        if (url != "") {
          this.$router.push(url);
        } else {
          this.$router.go();
        }
      }
      // this.pageLoadingOff();
    },
    toast(title, message) {
      var append = false;
      var toaster = "b-toaster-top-right";
      var variant = "danger";
      var icon = "error";
      switch (title) {
        case "success":
          icon = "success";
          variant = "success";
          break;
        case "error":
          variant = "danger";
          icon = "error";
          break;
        case "warning":
          variant = "warning";
          icon = "warning";
          break;
        default:
          variant = "info";
          icon = "info";
          break;
      }
      console.log("icon=" + icon);

      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: toaster,
        appendToast: append,
        variant: variant,
        solid: true,
      });
    },
    sweetAlert(title, message, timer) {
      console.log("sweetAlert called");
      var icon = "";
      switch (title) {
        case "success":
          icon = "success";
          break;
        case "error":
          icon = "error";
          break;
        default:
          icon = "info";
          break;
      }
      console.log("icon=" + icon);
      var options = {
        title: "",
        text: message,
        icon: icon,
      };
      if (timer) {
        options.timer = 1500;
        options.timerProgressBar = true;
        options.showConfirmButton = false;
      } else {
        options.showConfirmButton = true;
      }
      Swal.fire(options);
    },
    getSelectBoxOptions(source, destination, condition, url) {
      console.log("getSelectBoxOptions function is called");

      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", condition=" +
          JSON.stringify(condition) +
          ", url=" +
          url
      );

      var server_url = companyConfig.apiURL;
      console.log("server_url=" + server_url);

      url = server_url + url;
      console.log("url=" + url);

      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      console.log("token=" + token);

      const thisIns = this;
      var output = "";
      var flag = 0;
      var records = [];
      var totalRecords = 0;

      var sourceValue = thisIns[source];
      console.log("sourceValue=" + sourceValue);

      var upload = {
        source: sourceValue,
        additional: condition,
      };
      console.log("upload=" + JSON.stringify(upload));

      this.$http({
        url: url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          output = response.data.output;
          flag = response.data.flag;
          totalRecords = records.length;
          console.log(
            "output=" +
              output +
              ", flag=" +
              flag +
              ", totalRecords=" +
              totalRecords
          );
          if (flag == 1) {
            records = response.data.records;
            thisIns[destination] = records;
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
    getPageDetails(destination, defaultValue, url, condition) {
      console.log("getPageDetails function is called");

      console.log(
        "destination=" +
          destination +
          ", url=" +
          url +
          ", condition=" +
          JSON.stringify(condition)
      );

      var server_url = companyConfig.apiURL;
      console.log("server_url=" + server_url);

      url = server_url + url;
      console.log("url=" + url);

      var token = this.$session.get("token");
      console.log("token=" + token);

      token = token == (null || undefined) ? 0 : token;

      const thisIns = this;
      var output = "";
      var flag = 0;
      var records = defaultValue;
      var totalRecords = 0;

      this.$http({
        url: url,
        method: "POST",
        data: condition,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          output = response.data.output;
          flag = response.data.flag;
          totalRecords = records.length;
          console.log(
            "output=" +
              output +
              ", flag=" +
              flag +
              ", totalRecords=" +
              totalRecords
          );
          if (flag == 1) {
            records = response.data.records;
            thisIns[destination] = records;
          } else {
            thisIns[destination] = records;
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns[destination] = records;
        });
    },
    pageLoadingOn() {
      // const loader = this.$loading.show();
    },
    pageLoadingOff() {
      // const loader = this.$loading.show();
      // this.$vs.loading.close();
      // loader.hide();
    },
    getActionsPermissions(element, where) {
      var url = "api/users/get_actions_permissions";
      this.getTableData(element, url, where);
    },
    getApiHeaders(ContentType = 1) {
      console.log("getApiHeaders function is called");
      console.log({ ContentType });

      var ApiVersion = companyConfig.ApiVersion;
      console.log({ ApiVersion });

      var ContentTypeHeader =
        ContentType == 1
          ? ApiVersion == 1
            ? "application/x-www-form-urlencoded"
            : "application/json"
          : "multipart/form-data";
      console.log({ ContentTypeHeader });

      var token = this.$session.get("token");
      token = token == (null || undefined) ? "" : token;
      console.log({ token });

      var headers = {};

      if (ApiVersion == 1) {
        headers = {
          "Content-Type": ContentTypeHeader,
        };
      } else {
        if (token != "") {
          headers = {
            "Content-Type": ContentTypeHeader,
            Authorization: "Bearer " + token,
          };
        } else {
          headers = {
            "Content-Type": ContentTypeHeader,
          };
        }
      }
      console.log({ headers });

      return headers;
    },
    getTableData(rows, url, where) {
      console.log("getTableData function is called");

      console.log(
        "rows=" + rows + ", url=" + url + ", where=" + JSON.stringify(where)
      );

      this.pageLoadingOn();

      var server_url = companyConfig.apiURL;
      console.log("server_url=" + server_url);

      url = server_url + url;
      console.log("url=" + url);

      const token = this.$session.get("token");

      if (token == "") {
        this.logout();
      }

      var ApiVersion = companyConfig.ApiVersion;
      console.log({ ApiVersion });

      var headers = this.getApiHeaders();

      const thisIns = this;
      var output = "";
      var flag = 0;
      var records = [];
      var totalRecords = 0;

      var upload = {
        token: token,
        where: where,
      };

      console.log("upload=" + JSON.stringify(upload));

      this.$http({
        url: url,
        method: "POST",
        data: upload,
        headers: headers,
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          thisIns.pageLoadingOff();
          if (flag == 1) {
            records = response.data.records;
            totalRecords = records.length;
            console.log("totalRecords=" + totalRecords);
            thisIns[rows] = records;
            // thisIns.$parent[rows]=records;
            // thisIns.successMessage(successTxt);
          } else {
            // thisIns.message=output
            // thisIns.errorMessage(errorTxt);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
    selectListYesNo() {
      var result = [
        {
          value: "",
          text: "--Select--",
        },
        {
          value: "0",
          text: "No",
        },
        {
          value: "1",
          text: "Yes",
        },
      ];
      return result;
    },
    selectListActiveStatus() {
      var result = [
        {
          value: "",
          text: "--Select Status--",
        },
        {
          value: "0",
          text: "InActive",
        },
        {
          value: "1",
          text: "Active",
        },
      ];
      return result;
    },
    selectListCreditDebit() {
      var result = [
        {
          value: "",
          text: "--Select Type--",
        },
        {
          value: 1,
          text: "Credit",
        },
        {
          value: 2,
          text: "Debit",
        },
      ];
      return result;
    },
    selectListIncludedYesNo() {
      var result = [
        {
          value: "",
          text: "--Select--",
        },
        {
          value: "1",
          text: "Yes",
        },
        {
          value: "2",
          text: "No",
        },
      ];
      return result;
    },
    getActiveStatusColor(status) {
      if (
        status == "Not completed" ||
        status == "Pending" ||
        status == "Process" ||
        status == "Returned" ||
        status == "Disabled" ||
        status == "Yet to complete" ||
        status == "Not eligible" ||
        status == "Suspended" ||
        status == "Waiting For Approval" ||
        status == "Waiting For Confirmation" ||
        status == "ZP Approval Pending" ||
        status == "NHQ Approval Pending"
      )
        return "warning";
      if (
        status == "Active" ||
        status == "Approved" ||
        status == "Used" ||
        status == "Enabled"
      )
        return this.themeConfig.success;
      if (
        status == "Rejected" ||
        status == "Not scored" ||
        status == "Dissaffiliated" ||
        status == "Inactive" ||
        status == "Cancelled"
      )
        return "#ff0000";
      if (
        status == "Completed" ||
        status == "Closed" ||
        status == "Scored" ||
        status == "Provisional"
      )
        return "primary";
      return "dark";
    },
  },
  computed: {},
};
</script>
