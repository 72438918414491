/*=========================================================================================
  File Name: moduleGstSlab.js
  Description: GstSlab Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleGstSlabState.js'
import mutations from './moduleGstSlabMutations.js'
import actions from './moduleGstSlabActions.js'
import getters from './moduleGstSlabGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}