/*=========================================================================================
  File Name: moduleLocalCessType.js
  Description: LocalCessType Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleLocalCessTypeState.js'
import mutations from './moduleLocalCessTypeMutations.js'
import actions from './moduleLocalCessTypeActions.js'
import getters from './moduleLocalCessTypeGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}