/*=========================================================================================
  File Name: moduleProductGroup.js
  Description: ProductGroup Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleProductGroupState.js'
import mutations from './moduleProductGroupMutations.js'
import actions from './moduleProductGroupActions.js'
import getters from './moduleProductGroupGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}