import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "login",
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: "blank" },
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/MyDashboard.vue')
  },

  // product

  {
    path: '/product/list',
    name: 'Product List',
    component: () => import('@/views/product/ProductList.vue')
  },
  {
    path: '/product/add-new-product',
    name: 'Add New Product',
    component: () => import('@/views/product/ProductAddNew.vue')
  },
  {
    path: '/product/edit',
    name: 'Edit Product',
    component: () => import('@/views/product/ProductEdit.vue')
  },

  // production

  {
    path: '/production/list',
    name: 'Production List',
    component: () => import('@/views/production/ProductionList.vue')
  },
  {
    path: '/production/start',
    name: 'Start Production',
    component: () => import('@/views/production/ProductionStart.vue')
  },
  {
    path: '/production/complete',
    name: 'Complete Production',
    component: () => import('@/views/production/ProductionComplete.vue')
  },

  // production rules

  {
    path: '/production-rules/list',
    name: 'Production Rules List',
    component: () => import('@/views/production_rules/ProductionRulesList.vue')
  },
  {
    path: '/production-rules/create',
    name: 'Add New Production Rules',
    component: () => import('@/views/production_rules/ProductionRulesCreate.vue')
  },

  // employee

  {
    path: '/employee/list',
    name: 'Employee List',
    component: () => import('@/views/employee/EmployeeList.vue')
  },
  {
    path: '/employee/create',
    name: 'Create Employee',
    component: () => import('@/views/employee/EmployeeCreate.vue')
  },
  {
    path: '/employee/edit',
    name: 'Edit Employee',
    component: () => import('@/views/employee/EmployeeEdit.vue')
  },

  // convertion

  {
    path: '/convertion/list',
    name: 'Production Convertion List',
    component: () => import('@/views/conversion/ConversionList.vue')
  },
  {
    path: '/convertion/create',
    name: 'Add New Production Convertion',
    component: () => import('@/views/conversion/ConversionCreate.vue')
  },

    // purchase

    {
      path: '/purchase/list',
      name: 'Purchase List',
      component: () => import('@/views/purchase/PurchaseList.vue')
    },
    {
      path: '/purchase/create',
      name: 'Create Purchase',
      component: () => import('@/views/purchase/PurchaseCreate.vue')
    },
    {
      path: '/purchase/edit',
      name: 'Edit Purchase',
      component: () => import('@/views/purchase/PurchaseEdit.vue')
    },

]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log({ to });
    console.log({ from });
    console.log({ savedPosition });
    return { x: 0, y: 0 }
  }
});

router.beforeEach(async (to, from, next) => {
  var ToName = to.name;
  console.log({ ToName });

  var fromPath = from.path;
  var toPath = to.path;
  console.log("navigation from=" + fromPath + ", to=" + toPath);

  var token = localStorage.getItem("token");
  token = token == (null || undefined) ? "" : token;
  console.log({ token });

  if (
    to.path == "/" ||
    to.path == "/login" ||
    to.path == "/*"
  ) {
    console.log("navigation true");
    next();
  } else {
    console.log("navigation false");
    if (token != "") {
      console.log("token is valid");
      next();
    } else {
      console.log("session is expired");
      router.push("/login");
    }
  }
});

export default router