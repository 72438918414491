/*=========================================================================================
  File Name: moduleProductionList.js
  Description: ProductionList Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleProductionListState.js'
import mutations from './moduleProductionListMutations.js'
import actions from './moduleProductionListActions.js'
import getters from './moduleProductionListGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}