/*=========================================================================================
  File Name: moduleLedger.js
  Description: Ledger Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleLedgerState.js'
import mutations from './moduleLedgerMutations.js'
import actions from './moduleLedgerActions.js'
import getters from './moduleLedgerGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}