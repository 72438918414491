/*=========================================================================================
  File Name: moduleProductComboPack.js
  Description: ProductComboPack Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleProductComboPackState.js'
import mutations from './moduleProductComboPackMutations.js'
import actions from './moduleProductComboPackActions.js'
import getters from './moduleProductComboPackGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}