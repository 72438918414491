/*=========================================================================================
  File Name: moduleGstSlabMutations.js
  Description: GstSlab Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {    
    ADD_NEW_RECORD(state, dataObj) {
        const newData = Object.assign({}, dataObj);
        state.dataArray.push(newData);
    },
    SET_PARENT_GROUP_NAME(state, payload) {
      state.dataArray[payload.sno].parent_group_name = payload.title;    
    },
    SET_PRICE_GROUP_NAME(state, payload) {
      state.dataArray[payload.sno].group_name = payload.title;    
    },
    SET_ACTIVE_STATUS(state, payload) {
      state.dataArray[payload.sno].active_status = payload.title;
    },
    LOAD_ALL_RECORDS(state, records) {
      state.dataArray = records;
    }    
}