<template>
  <div id="blank-layout">
    <v-app>
      <body id="kt_body" class="bg-body">
        <div class="d-flex flex-column flex-root">
          <div
            class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            :style="{
              backgroundImage: `url(${require('@/assets/media/illustrations/sketchy-1/14.png')})`,
            }"
          >
            <slot />
            <div class="d-flex align-items-center flex-center flex-column-auto">
              <p class="footer-title">
                Made with <v-icon left color="red"> mdi-heart </v-icon> by
                <a href="https://vaagai.org.in" target="_blank"
                  >Vaagai Tecknowlege</a
                >
              </p>
            </div>
          </div>
        </div>
      </body>
    </v-app>
  </div>
</template>

<script>
export default {
  data() {
    return {
      CurrentYear: new Date().getFullYear(),
    };
  },
};
</script>