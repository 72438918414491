/*=========================================================================================
  File Name: moduleSettings.js
  Description: Settings Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleSettingsState.js'
import mutations from './moduleSettingsMutations.js'
import actions from './moduleSettingsActions.js'
import getters from './moduleSettingsGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
