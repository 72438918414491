/*=========================================================================================
  File Name: moduleAllParty.js
  Description: AllParty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleAllPartyState.js'
import mutations from './moduleAllPartyMutations.js'
import actions from './moduleAllPartyActions.js'
import getters from './moduleAllPartyGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}