/*=========================================================================================
  File Name: moduleLocalCessTypeState.js
  Description: LocalCessType Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


export default {
    dataArray: []
}