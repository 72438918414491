/*=========================================================================================
  File Name: modulePurchaseState.js
  Description: Purchase Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


export default {
  data: {},
  dataArray: [],
  StatusFlag: false
}