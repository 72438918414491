<template>
  <div class="mt-3" id="menu-lists">
    <div
      class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
    >
      <!-- <div class="menu-item my-menu-item">
        <router-link to="/dashboard" class="py-3">
          <span class="menu-tit">Dashboard</span>
        </router-link>
      </div> -->
      <div class="menu-item my-menu-item">
        <router-link to="/product/list" class="py-3">
          <span class="menu-tit">Products</span>
        </router-link>
      </div>
      <div class="menu-item">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <span class="menu-tit dropdown my-menu-item"
              >Persons <b-icon icon="caret-down-fill" font-scale="0.9" />
            </span>
          </template>
          <div class="router-li my-menu-item">
            <router-link to="/employee/list">
              <div class="menu-item me-lg-1 pt-3 pb-3 dropdown-it">
                <span class="dropdown-ite">Employee</span>
              </div>
            </router-link>
          </div>
        </b-dropdown>
      </div>
      <div class="menu-item">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <span class="menu-tit dropdown my-menu-item"
              >Production <b-icon icon="caret-down-fill" font-scale="0.9" />
            </span>
          </template>
          <div class="router-li my-menu-item">
            <router-link to="/convertion/list">
              <div class="menu-item me-lg-1 pt-3 pb-3 dropdown-it">
                <span class="dropdown-ite">Production Convertion</span>
              </div>
            </router-link>
          </div>

          <div class="router-li my-menu-item">
            <router-link to="/production/list">
              <div class="menu-item me-lg-1 pt-3 pb-3 dropdown-it">
                <span class="dropdown-ite">Production List</span>
              </div>
            </router-link>
          </div>

          <div class="router-li my-menu-item">
            <router-link to="/production-rules/list">
              <div class="menu-item pt-3 pb-3 dropdown-it">
                <span class="dropdown-ite">Production Rules List</span>
              </div>
            </router-link>
          </div>
        </b-dropdown>
      </div>

      <div class="menu-item">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <span class="menu-tit dropdown my-menu-item"
              >Purchase <b-icon icon="caret-down-fill" font-scale="0.9" />
            </span>
          </template>
          <div class="router-li my-menu-item">
            <router-link to="/purchase/list">
              <div class="menu-item me-lg-1 pt-3 pb-3 dropdown-it">
                <span class="dropdown-ite">Purchase List</span>
              </div>
            </router-link>
          </div>
        </b-dropdown>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBtn: false,
    };
  },
  methods: {
    changeColor() {
      console.log("changeColor called");
    },
  },
};
</script>

<style lang="scss" scoped>
#menu-lists {
  @media (max-width: 991px) {
    .menu-item {
      padding-bottom: 14px !important;
      padding-left: 4px !important;
    }
    .dropdown {
      margin-top: -10px !important;
    }
    .dropdown-it {
      width: 280px;
    }

    .menu-tit:active {
      color: #4fc1ee;
      background-color: rgb(237, 245, 248);
      width: max-content;
    }
    .router-li:active {
      background-color: rgb(237, 245, 248);
    }
    .router-li:active span {
      color: rgb(79, 193, 238);
    }
    .dropdown-ite:active {
      color: rgb(79, 193, 238);
      background-color: rgb(237, 245, 248);
    }
  }
  @media (min-width: 992px) {
    .dropdown-it {
      width: max-content;
    }
  }
  .menu-tit {
    color: rgb(55, 53, 53);
    border-radius: 4px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 13px;
    padding-right: 13px;
  }
  .menu-tit:hover {
    color: #4fc1ee;
    background-color: rgb(237, 245, 248);
    width: max-content;
  }
  .router-li {
    color: #373535;
    margin-left: -14px;
    margin-right: 10px;
    border-radius: 4px;
  }
  .router-li:hover {
    background-color: rgb(237, 245, 248);
  }
  .router-li:hover span {
    color: rgb(79, 193, 238);
  }
  .dropdown-ite {
    color: rgb(55, 53, 53);
    margin-left: 10px;
    margin-right: 10px;
  }
  .dropdown-ite:hover {
    color: rgb(79, 193, 238);
    background-color: rgb(237, 245, 248);
  }
}
</style>