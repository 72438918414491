/*=========================================================================================
  File Name: modulePaymentMode.js
  Description: PaymentMode Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './modulePaymentModeState.js'
import mutations from './modulePaymentModeMutations.js'
import actions from './modulePaymentModeActions.js'
import getters from './modulePaymentModeGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}