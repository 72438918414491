/*=========================================================================================
  File Name: moduleAllCompanyParty.js
  Description: AllAllCompanyParty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleAllCompanyPartyState.js'
import mutations from './moduleAllCompanyPartyMutations.js'
import actions from './moduleAllCompanyPartyActions.js'
import getters from './moduleAllCompanyPartyGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}