/*=========================================================================================
  File Name: moduleDeliveryChallanState.js
  Description: DeliveryChallan Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


export default {
    dataArray: [],
    StatusFlag: false
}