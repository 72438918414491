/*=========================================================================================
  File Name: moduleLedgerGroup.js
  Description: LedgerGroup Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleLedgerGroupState.js'
import mutations from './moduleLedgerGroupMutations.js'
import actions from './moduleLedgerGroupActions.js'
import getters from './moduleLedgerGroupGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}