/*=========================================================================================
  File Name: moduleCompanyRegistration.js
  Description: CompanyRegistration Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleCompanyRegistrationState.js'
import mutations from './moduleCompanyRegistrationMutations.js'
import actions from './moduleCompanyRegistrationActions.js'
import getters from './moduleCompanyRegistrationGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}