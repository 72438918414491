/*=========================================================================================
  File Name: modulePartyPriceGroupState.js
  Description: GstSlab Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    dataArray: []
}