/*=========================================================================================
  File Name: moduleProductGetters.js
  Description: Product Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


export default {
  dataArrayLength: state => state.dataArray.length,
  dataList: state => {
    return state.dataArray;
  },
  data: state => {
    return state.data;
  },
}