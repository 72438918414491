<template>
  <!--begin::Footer-->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <!-- <span class="text-muted fw-bold me-1">{{ CurrentYear }}©</span> -->
        <!-- <a
          href="https://vaagai.org.in"
          target="_blank"
          class="text-gray-800 text-hover-primary"
          >Vaagai Tecknowlege</a
        > -->
      </div>

      <div class="text-dark order-2 order-md-1">
        <!-- <span class="text-muted fw-bold me-1">{{ CurrentYear }}©</span> -->
        <p class="footer-title">
          Made with <v-icon left color="red"> mdi-heart </v-icon> by
          <a href="https://vaagai.org.in" target="_blank">Vaagai Tecknowlege</a>
        </p>
      </div>

      <!--end::Copyright-->
      <!--begin::Menu-->
      <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <!-- <li class="menu-item">
          <a
            href="https://keenthemes.com"
            target="_blank"
            class="menu-link px-2"
            >About</a
          >
        </li>
        <li class="menu-item">
          <a
            href="https://devs.keenthemes.com"
            target="_blank"
            class="menu-link px-2"
            >Support</a
          >
        </li>
        <li class="menu-item">
          <a
            href="https://1.envato.market/EA4JP"
            target="_blank"
            class="menu-link px-2"
            >Purchase</a
          >
        </li> -->
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Footer-->
</template>

<script>
export default {
  data() {
    return {
      CurrentYear: new Date().getFullYear(),
    };
  },
};
</script>