/*=========================================================================================
  File Name: moduleGstSlabGetters.js
  Description: GstSlab Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  dataArrayLength: state => state.dataArray.length,
  dataList: state => {
      return state.dataArray;
  },
}